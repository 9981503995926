import { useTranslation } from 'react-i18next';
import CollectFieldData, {
    ContraintCaptionDefinition,
    DataPointCaptionDefinition,
} from '../collectors/CollectFieldData';
import {DataPointDefinition} from '../api/sentinel';
import allDataPoints from './useAllDataPointDefinitions';
import projectDataPoints from './useProjectDataPointDefinitions';
import sprintDataPoints from './usePeriodDataPointDefinitions';

const contraintsCaption: ContraintCaptionDefinition = {
    required: '{title} must be provided',
    allowedAnyOfOrNone: '{title} needs any of {options} or none',
    allowedAnyOf: '{title} needs any of {options}',
    allowedOneOf: '{title} needs one of {options}',
    greaterThanOrEqualTo: '{title} must be greater than or equal to {0}',
    lessThanOrEqualTo: '{title} must be less than or equal to {0}',
};

export interface DataPointDefinitionRequest {
    projectId?: string;
    periodId?: number;
}

export interface DataPointDefinitions {
    allDataPoints: CollectFieldData[];
    configuredDataPoints: CollectFieldData[];
    isLoading: boolean;
}

function isProjectRequest(request: DataPointDefinitionRequest): boolean {
    return request.projectId !== undefined;
}

function isPeriodRequest(request: DataPointDefinitionRequest): boolean {
    return request.periodId !== undefined && request.projectId !== undefined;
}

function adaptToLegacy(fieldData: CollectFieldData[]): CollectFieldData[] {
    setOptions(
        'deploymentTarget',
        ['NONE', 'CI', 'TEST', 'UAT', 'PROD_STAGING'],
        fieldData,
    );
    setOptions(
        'ciActivity',
        [
            'NONE',
            'COMPILES',
            'COMPILES_STATIC_ANALYSIS',
            'COMPILES_STATIC_ANALYSIS_RUNS_TESTS',
            'COMPILES_STATIC_ANALYSIS_RUNS_TESTS_GENERATES_ARTIFACT',
        ],
        fieldData,
    );
    return fieldData;
}

function setOptions(
    fieldId: string,
    options: string[],
    fieldData: CollectFieldData[],
) {
    fieldData
        .filter(data => data.dataPoint.id == fieldId)
        .forEach(data => {
            data.dataPoint.options = new Set(options);
        });
}

const useDataPointDefinitions = (
    request: DataPointDefinitionRequest,
): DataPointDefinitions => {
    const { t } = useTranslation();
    const tx = (key?: string) => {
        if (!key) return undefined;
        const result = t(key);
        return result !== key ? result : undefined;
    };

    function createCaption(id: string): DataPointCaptionDefinition {
        return {
            title: t(`sprintForm_${id}_label`),
            directiveText: tx(`sprintForm_${id}_helper`),
            help: tx(`sprintForm_${id}_tooltip`),
        };
    }

    function decorateDataPointsDefinitions(
        definitions: DataPointDefinition[],
    ): CollectFieldData[] {
        return adaptToLegacy(
            definitions.map(data => {
                return {
                    dataPoint: data,
                    contraintsCaption: contraintsCaption,
                    caption: createCaption(data.id),
                };
            }),
        );
    }

    function recoverConfiguredDataPoints(
        request: DataPointDefinitionRequest,
        defaultAllDataPoints: {
            dataPoints: DataPointDefinition[];
            isLoading: boolean;
        },
    ): { dataPoints: DataPointDefinition[]; isLoading: boolean } {
        if (isPeriodRequest(request)) {
            return sprintDataPoints(request.projectId!, request.periodId!);
        } else if (isProjectRequest(request)) {
            return projectDataPoints(request.projectId!);
        } else {
            return defaultAllDataPoints;
        }
    }

    const dataPointsResponse = allDataPoints();
    const ciActivity : DataPointDefinition = dataPointsResponse.dataPoints.find(def => def.id === 'ciActivity') || ciActivityFactory();
    const deploymentTarget : DataPointDefinition = dataPointsResponse.dataPoints.find(def => def.id === 'deploymentTarget') || deploymentTargetFactory();

    const configuredDataPointsResponse = recoverConfiguredDataPoints(
        request,
        dataPointsResponse,
    );

    const dataPoints = dataPointsResponse.dataPoints ?? [];
    const configuredDataPoints = configuredDataPointsResponse.dataPoints ?? [];
    if (configuredDataPoints.some(def => def.id.startsWith('ciActivity'))
        && !configuredDataPoints.some(def => def.id === 'ciActivity')) {
        configuredDataPoints.push(ciActivity);
    }
    if (configuredDataPoints.some(def => def.id.startsWith('deploymentTarget'))
        && !configuredDataPoints.some(def => def.id === 'deploymentTarget')) {
        configuredDataPoints.push(deploymentTarget);
    }

    const isLoading =
        dataPointsResponse.isLoading || configuredDataPointsResponse.isLoading;

    const decoratedDataPoints: CollectFieldData[] =
        decorateDataPointsDefinitions(dataPoints);
    const decoratedConfiguredDataPoints: CollectFieldData[] =
        decorateDataPointsDefinitions(configuredDataPoints);



    return {
        allDataPoints: decoratedDataPoints,
        configuredDataPoints: decoratedConfiguredDataPoints,
        isLoading: isLoading,
    };
};

function ciActivityFactory() : DataPointDefinition {
    return {
        id: 'ciActivity',
        type: 'ENUMERATION',
        category: 'ENVIRONMENT',
        valueConstraint: { required: false },
        restriction: {
            readAccessRoles: new Set(['read', 'write', 'leader']),
            writeAccessRoles: new Set(['write', 'leader'])
        }
    };
}

function deploymentTargetFactory() : DataPointDefinition {
    return {
        id: 'deploymentTarget',
        type: 'ENUMERATION',
        category: 'ENVIRONMENT',
        valueConstraint: { required: false },
        restriction: {
            readAccessRoles: new Set(['read', 'write', 'leader']),
            writeAccessRoles: new Set(['write', 'leader'])
        }
    };
}

function throwException(message: string): CollectFieldData {
    throw new Error(message);
}

export function findFieldData(
    fieldData: CollectFieldData[],
    metricName: string,
): CollectFieldData {
    return (
        fieldData.find(f => f.dataPoint.id === metricName) ??
        throwException(`Field definition for metric ${metricName} not found`)
    );
}

export default useDataPointDefinitions;

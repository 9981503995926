// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ProjectPeriodData } from './ProjectPeriodData';
import {
    instanceOfProjectPeriodData,
    ProjectPeriodDataFromJSON,
    ProjectPeriodDataFromJSONTyped,
    ProjectPeriodDataToJSON,
} from './ProjectPeriodData';

/**
 * @type PageContentInner
 *
 * @export
 */
export type PageContentInner = ProjectPeriodData;

export function PageContentInnerFromJSON(json: any): PageContentInner {
    return PageContentInnerFromJSONTyped(json, false);
}

export function PageContentInnerFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): PageContentInner {
    if (json === undefined || json === null) {
        return json;
    }
    return { ...ProjectPeriodDataFromJSONTyped(json, true) };
}

export function PageContentInnerToJSON(value?: PageContentInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfProjectPeriodData(value)) {
        return ProjectPeriodDataToJSON(value as ProjectPeriodData);
    }

    return {};
}

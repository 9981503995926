import { useParams } from 'react-router-dom';
import Spinner from '../common/Spinner';
import { Container } from '@mui/material';
import ChangesetsPanel from './ChangesetsPanel';
import useProjectData from '../project/useProjectData';
import useProjectChangeHistory from '../project/useProjectChangeHistory';
import { Changeset } from '../api/sentinel';

const ProjectChangeHistory = () => {
    const { projectId } = useParams();
    const history = useProjectChangeHistory(projectId);
    const project = useProjectData(projectId);
    const fieldsOfArrayType = [
        {
            fieldName: 'projectLinks',
            projectedField: 'url',
            regex: '([\\w\\s:/.]+)',
        },
        {
            fieldName: 'locations',
            projectedField: 'location',
            regex: '([\\w\\s]+)',
        },
    ];
    if (history.isLoading || project.isLoading) {
        return <Spinner open={true} />;
    }
    if (history.error || project.error) {
        return <>Error: {history.error?.message ?? project.error?.message}</>;
    }

    function extractFieldValue(
        fieldValue: string | undefined,
        projectedField: string,
        pattern: string,
    ) {
        return fieldValue
            ?.match(new RegExp(`${projectedField}=${pattern}`, 'gi'))
            ?.map(m => m.split(`${projectedField}=`)[1])
            .join('\r\n');
    }

    function adaptChanges(copiedChangeset: Changeset) {
        copiedChangeset.changes.forEach(change => {
            fieldsOfArrayType.forEach(fields => {
                if (change.fieldName === fields.fieldName) {
                    change.newValue = extractFieldValue(
                        change.newValue,
                        fields.projectedField,
                        fields.regex,
                    );

                    change.oldValue = extractFieldValue(
                        change.oldValue,
                        fields.projectedField,
                        fields.regex,
                    );
                }
            });
        });
    }

    function adaptChangesetChanges() {
        const copiedChangesets: Changeset[] =
            history.data?.map(d => ({ ...d })) ?? [];
        copiedChangesets?.forEach(copiedChangeset => {
            copiedChangeset.changes = copiedChangeset.changes.map(c => ({
                ...c,
            }));
            adaptChanges(copiedChangeset);
        });
        return copiedChangesets;
    }

    return (
        <>
            <Container maxWidth={false}>
                <ChangesetsPanel
                    values={adaptChangesetChanges()}
                    projectId={projectId}
                    projectName={project.formModel.projectName}
                />
            </Container>
        </>
    );
};

export default ProjectChangeHistory;

// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PageContentInner } from './PageContentInner';
import {
    PageContentInnerFromJSON,
    PageContentInnerFromJSONTyped,
    PageContentInnerToJSON,
} from './PageContentInner';
import type { Pageable } from './Pageable';
import {
    PageableFromJSON,
    PageableFromJSONTyped,
    PageableToJSON,
} from './Pageable';
import type { Sort } from './Sort';
import { SortFromJSON, SortFromJSONTyped, SortToJSON } from './Sort';

import { ProjectPeriodDataFromJSONTyped } from './index';

/**
 *
 * @export
 * @interface Page
 */
export interface Page {
    /**
     *
     * @type {Array<PageContentInner>}
     * @memberof Page
     */
    content?: Array<PageContentInner>;
    /**
     *
     * @type {Pageable}
     * @memberof Page
     */
    pageable?: Pageable;
    /**
     *
     * @type {number}
     * @memberof Page
     */
    totalElements?: number;
    /**
     *
     * @type {number}
     * @memberof Page
     */
    totalPages?: number;
    /**
     *
     * @type {boolean}
     * @memberof Page
     */
    last?: boolean;
    /**
     *
     * @type {number}
     * @memberof Page
     */
    size?: number;
    /**
     *
     * @type {number}
     * @memberof Page
     */
    number?: number;
    /**
     *
     * @type {Sort}
     * @memberof Page
     */
    sort?: Sort;
    /**
     *
     * @type {number}
     * @memberof Page
     */
    numberOfElements?: number;
    /**
     *
     * @type {boolean}
     * @memberof Page
     */
    first?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Page
     */
    empty?: boolean;
}

/**
 * Check if a given object implements the Page interface.
 */
export function instanceOfPage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageFromJSON(json: any): Page {
    return PageFromJSONTyped(json, false);
}

export function PageFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): Page {
    if (json === undefined || json === null) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['objectType'] === 'Project-Period-Data') {
            return ProjectPeriodDataFromJSONTyped(json, true);
        }
    }
    return {
        content: !exists(json, 'content')
            ? undefined
            : (json['content'] as Array<any>).map(PageContentInnerFromJSON),
        pageable: !exists(json, 'pageable')
            ? undefined
            : PageableFromJSON(json['pageable']),
        totalElements: !exists(json, 'totalElements')
            ? undefined
            : json['totalElements'],
        totalPages: !exists(json, 'totalPages')
            ? undefined
            : json['totalPages'],
        last: !exists(json, 'last') ? undefined : json['last'],
        size: !exists(json, 'size') ? undefined : json['size'],
        number: !exists(json, 'number') ? undefined : json['number'],
        sort: !exists(json, 'sort') ? undefined : SortFromJSON(json['sort']),
        numberOfElements: !exists(json, 'numberOfElements')
            ? undefined
            : json['numberOfElements'],
        first: !exists(json, 'first') ? undefined : json['first'],
        empty: !exists(json, 'empty') ? undefined : json['empty'],
    };
}

export function PageToJSON(value?: Page | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        content:
            value.content === undefined
                ? undefined
                : (value.content as Array<any>).map(PageContentInnerToJSON),
        pageable: PageableToJSON(value.pageable),
        totalElements: value.totalElements,
        totalPages: value.totalPages,
        last: value.last,
        size: value.size,
        number: value.number,
        sort: SortToJSON(value.sort),
        numberOfElements: value.numberOfElements,
        first: value.first,
        empty: value.empty,
    };
}

import { Providers, Token } from '../../authentication/tokenProvider';
import config from '../../services/config';

export interface ExportRequest {
    projectIds?: string[];
    formatName: string;
    fileName: string;
}

const resolveFileContent = async (request: ExportRequest, token: Token) => {
    const requestUrl = new URL(
        `${config.get('REACT_APP_SPRING_API_URL')}/periods/download`,
    );
    return await fetch(
        `${requestUrl.toString()}${request?.projectIds && request.projectIds.length > 0 ? `?projectIds=${request.projectIds}` : ''}`,
        {
            method: 'GET',
            headers: {
                Accept: `application/${request?.formatName?.toLowerCase() || 'json'}`,
                Authorization: `Bearer ${token.value()}`,
            },
        },
    );
};

const triggerDownload = async (request: ExportRequest, response: Response) => {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${request.fileName}.${request.formatName}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const downloadProjectData = async (request: ExportRequest) => {
    const token = await Providers.applicationApiProvider.getToken();
    const response = await resolveFileContent(request, token);
    await triggerDownload(request, response);
};

export const DownloadService = {
    downloadProjectData,
};

export default DownloadService;

import {
    DataGrid,
    GridColDef,
    GridColumnVisibilityModel,
    gridExpandedSortedRowIdsSelector,
    GridFilterModel,
    GridRowParams,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    useGridApiRef,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../api/sentinel';
import { EditProjectCell } from './EditProjectCell';
import LocationsCell from './LocationsCell';
import SprintAgeCell from './SprintAgeCell';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import OverallScoreCell from './OverallScoreCell';
import dateService from '../../services/dateService';
import './personAvatar.css';
import { Person } from '@microsoft/mgt-react';
import { setPref, getPref } from '../../services/localStoragePreferences';
import IconTooltip from '../../common/formContextBoundControls/IconTooltip';
import ExportProjectData from '../../export/ExportProjectData';
import { Box, Stack } from '@mui/material';
import { authService } from '../../authentication/useAuth';

const ProjectsDataGrid = ({
    projects,
    activeFilter,
    showOverallScore = false,
}: {
    projects: Project[];
    activeFilter: boolean;
    showOverallScore?: boolean;
}) => {
    const SENTINEL_PRJ_LST__FILTER = 'SNTL_PRJ_LST__filter';
    const SENTINEL_PRJ_LST__COLUMNS_VISIBILITY =
        'SNTL_PRJ_LST__columns-visibility';

    const [filterModel, setFilterModel]: [
        GridFilterModel,
        Dispatch<SetStateAction<GridFilterModel>>,
    ] = useState<GridFilterModel>(() => {
        const savedFilterModel = getPref(SENTINEL_PRJ_LST__FILTER);
        return savedFilterModel ? JSON.parse(savedFilterModel) : { items: [] };
    });
    const navigate = useNavigate();
    const { t } = useTranslation();
    const translation = {
        sprintAgeInfo: t('projectList_sprintAge_info'),
        overallScoreInfo: t('projectList_overallScore_info'),
        dashboardExportDataLabel: t('projectDashboard_export_data_label'),
    };
    const [columnVisibilityModel, setColumnVisibilityModel]: [
        GridColumnVisibilityModel,
        Dispatch<SetStateAction<GridColumnVisibilityModel>>,
    ] = useState<GridColumnVisibilityModel>(() => {
        const savedVisibilityModel = getPref(
            SENTINEL_PRJ_LST__COLUMNS_VISIBILITY,
        );
        return savedVisibilityModel ? JSON.parse(savedVisibilityModel) : {};
    });

    const apiRef = useGridApiRef();

    useEffect(() => {
        setColumnVisibilityModel({ sprintAge: activeFilter });
    }, [activeFilter]);

    const handleRowClick = (e: GridRowParams) => {
        navigate(`/Dashboard/${e.row.projectId}`);
    };

    const commonAttributes = {
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
    };

    const field = (
        fieldName: keyof Project | 'grade' | 'edit',
        options?: Partial<GridColDef>,
    ): GridColDef => {
        return {
            field: fieldName,
            headerName: t(`projectList_${fieldName}`),
            ...commonAttributes,
            ...options,
        };
    };

    type GridComparatorFn = (
        left: string | undefined,
        right: string | undefined,
    ) => number;
    const userComparator: GridComparatorFn = (left, right) => {
        if (left === right) return 0;
        if (left === undefined) return 1;
        if (right === undefined) return -1;
        return left < right ? -1 : 1;
    };

    const engagementModel = (value: string) => {
        if (!value) {
            return null;
        }
        return t(`projectForm_engagementModel__${value.toUpperCase()}`);
    };

    const projectColumns: GridColDef[] = [
        field('projectName', {
            flex: 1.5,
            minWidth: 220,
            hideable: false,
            filterable: true,
            renderCell: ({ row }) => {
                return (
                    <>
                        {row.projectName}
                        {row.commentary && (
                            <IconTooltip
                                title={row.commentary}
                                placement="right"
                                iconName="announcement"
                                maxWidth="800px"
                                id="projectCommentary"
                            ></IconTooltip>
                        )}
                    </>
                );
            },
        }),
        field('businessId', {
            flex: 1,
            filterable: true,
        }),
        field('clientName', {
            flex: 1.5,
            minWidth: 180,
            filterable: true,
        }),
        field('engagementManager', {
            sortComparator: userComparator,
            flex: 1.2,
            minWidth: 170,
            filterable: true,
            valueGetter: ({ row }) => row.engagementManager?.displayName || '',
            renderCell: ({ row }) => {
                return (
                    row.engagementManager && (
                        <Person
                            view="oneline"
                            className="mgt-person"
                            key={row.engagementManager?.userId}
                            userId={row.engagementManager?.userId}
                            personCardInteraction="none"
                            avatarType="photo"
                        />
                    )
                );
            },
        }),
        field('engagementModel', {
            valueGetter: ({ row }) => {
                return engagementModel(row.engagementModel);
            },
            flex: 1.2,
            minWidth: 130,
            filterable: true,
        }),
        field('scrumMaster', {
            sortComparator: userComparator,
            filterable: true,
            valueGetter: ({ row }) => row.scrumMaster?.displayName || '',
            flex: 1.2,
            renderCell: ({ row }) => {
                return (
                    row.scrumMaster && (
                        <Person
                            view="oneline"
                            className="mgt-person"
                            key={row.scrumMaster?.userId}
                            userId={row.scrumMaster?.userId}
                            personCardInteraction="none"
                            avatarType="photo"
                        />
                    )
                );
            },
        }),
        field('practicePartner', {
            sortComparator: userComparator,
            filterable: true,
            valueGetter: ({ row }) => row.practicePartner?.displayName || '',
            flex: 1.4,
            renderCell: ({ row }) => {
                return (
                    row.practicePartner && (
                        <Person
                            view="oneline"
                            className="mgt-person"
                            key={row.practicePartner?.userId}
                            userId={row.practicePartner?.userId}
                            personCardInteraction="none"
                            avatarType="photo"
                        />
                    )
                );
            },
        }),
        field('startDate', {
            type: 'date',
            valueFormatter: ({ value }) =>
                dateService.toStandardDateFormat(value),
            maxWidth: 95,
        }),
        field('endDate', {
            type: 'date',
            valueFormatter: ({ value }) =>
                dateService.toStandardDateFormat(value),
            maxWidth: 95,
        }),
        field('sprintAge', {
            renderCell: SprintAgeCell,
            description: translation.sprintAgeInfo,
            align: 'center',
            headerAlign: 'center',
            filterable: true,
            sortable: true,
            type: 'number',
            maxWidth: 90,
        }),
        field('overallScore', {
            renderCell: OverallScoreCell,
            description: translation.overallScoreInfo,
            align: 'center',
            headerAlign: 'center',
            filterable: true,
            sortable: true,
            type: 'number',
            maxWidth: 120,
        }),
        field('locations', {
            renderCell: LocationsCell,
            flex: 1.4,
            minWidth: 120,
        }),
        field('edit', {
            maxWidth: 50,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            renderCell: EditProjectCell,
            hideable: authService.hasEditingRights(),
        }),
    ];

    const handleColumnVisibilityChange = (
        newVisibilityModel: GridColumnVisibilityModel,
    ) => {
        setColumnVisibilityModel(newVisibilityModel);
        setPref(
            SENTINEL_PRJ_LST__COLUMNS_VISIBILITY,
            JSON.stringify(newVisibilityModel),
        );
    };

    useEffect(() => {
        const savedVisibilityModel = getPref(
            SENTINEL_PRJ_LST__COLUMNS_VISIBILITY,
        );
        savedVisibilityModel &&
            setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }, []);

    const [filterProjectIds, setFilterProjectIds] = useState<string[]>([]);
    useEffect(() => {
        if (apiRef) {
            const getFilteredRowIds = () =>
                gridExpandedSortedRowIdsSelector(apiRef).map(d =>
                    d.toString(),
                ) ?? [];
            setFilterProjectIds(getFilteredRowIds());
        }
    }, [apiRef, filterModel]);

    const handleFilterChange = (newFilterModel: GridFilterModel) => {
        setFilterModel(newFilterModel);

        const filters = newFilterModel.items.map(item => {
            const { field, value, operator } = item;
            return { field, value, operator };
        });

        const filtersToObject = {
            items: filters,
        };

        setPref(SENTINEL_PRJ_LST__FILTER, JSON.stringify(filtersToObject));
    };

    if (!showOverallScore) {
        projectColumns.splice(
            projectColumns.findIndex(item => item.field === 'overallScore'),
            1,
        );
    }

    return (
        <DataGrid
            sortingOrder={['desc', 'asc']}
            autoHeight
            columns={projectColumns}
            density={'standard'}
            onRowClick={handleRowClick}
            initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
            rows={projects}
            getRowId={(project: Project) => project.projectId ?? ''}
            pageSizeOptions={[10]}
            slots={{
                toolbar: () => (
                    <Stack
                        direction="row"
                        spacing="2"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box>
                            <GridToolbarColumnsButton />
                            <GridToolbarFilterButton />
                        </Box>
                        <Box>
                            <ExportProjectData
                                labelName={translation.dashboardExportDataLabel}
                                projectIds={filterProjectIds}
                                projects ={projects}
                                minimumWidth="200px"
                            ></ExportProjectData>
                        </Box>
                    </Stack>
                ),
            }}
            disableDensitySelector
            slotProps={{
                toolbar: {
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                },
            }}
            disableVirtualization
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
            filterModel={filterModel}
            onFilterModelChange={handleFilterChange}
            sx={{ pt: '8px' }}
            apiRef={apiRef}
        />
    );
};

export default ProjectsDataGrid;

import {createTheme, Theme} from '@mui/material/styles';
import {LinkProps} from '@mui/material/Link';
import {LinkBehavior} from './linkBehavior';
import React from 'react';
import {grey} from '@mui/material/colors';

const colors = {
    default: {
        sentinelBlue: '#002c56',
        sentinelWhite: '#fff',
        sentinelExtraLightGrey: grey[100],
        sentinelLightGrey: grey[300],
        sentinelMidGrey: grey[400],
        sentinelBlueHover: '#004585',
        muiGrey: '#00000099',
        errorRed: '#d32f2f',
        sentinelBoxShadowBtn:
            '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
};

const theme: Theme = createTheme({
    components: {
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            } as LinkProps,
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                row: {
                    cursor: 'pointer',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    variants: [
                        {
                            props: {
                                color: 'primary',
                                component: 'button',
                                variant: 'contained',
                            },
                            style: {
                                backgroundColor: colors.default.sentinelBlue,
                                boxShadow: 'none',
                                color: colors.default.sentinelWhite,
                                fontWeight: 500,
                                lineHeight: 1,
                                minWidth: 'auto',
                                padding: '8px 16px',
                                textTransform: 'uppercase',
                                '&:hover': {
                                    backgroundColor:
                                    colors.default.sentinelBlueHover,
                                    borderColor:
                                    colors.default.sentinelBlueHover,
                                    boxShadow:
                                    colors.default.sentinelBoxShadowBtn,
                                },
                                '&.Mui-disabled': {
                                    backgroundColor:
                                    colors.default.sentinelLightGrey,
                                    border: `solid 1px ${colors.default.sentinelLightGrey}`,
                                    color: colors.default.sentinelMidGrey,
                                },
                            },
                        },
                        {
                            props: {
                                color: 'secondary',
                                component: 'button',
                                variant: 'outlined',
                            },
                            style: {
                                backgroundColor: colors.default.sentinelWhite,
                                borderColor: colors.default.sentinelBlue,
                                color: colors.default.sentinelBlue,
                                fontWeight: 500,
                                lineHeight: 1,
                                minWidth: 'auto',
                                padding: '8px 16px',
                                textTransform: 'uppercase',
                                '&:hover': {
                                    borderColor:
                                    colors.default.sentinelBlueHover,
                                    boxShadow:
                                    colors.default.sentinelBoxShadowBtn,
                                    color: colors.default.sentinelBlueHover,
                                },
                                '&.Mui-disabled': {
                                    border: `solid 1px ${colors.default.sentinelLightGrey}`,
                                    color: colors.default.sentinelMidGrey,
                                },
                            },
                        },
                    ],
                },
            },
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    '&.CTAGroup': {
                        flexDirection: 'row',
                        '& button:not(:first-child)': {
                            marginLeft: '12px',
                        },
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.export': {
                        '> .MuiBox-root': {
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                        },
                        '&.initialPos .MuiFormLabel-root:not(.MuiFormLabel-filled)':
                            {
                                transform: 'translate(0, 20px) scale(1)',
                            },
                        '.MuiInputLabel-root': {
                            fontSize: '13px',
                            marginTop: '-12px',
                            marginLeft: '20px',
                            color: colors.default.sentinelBlue,
                            fontWeight: '500',
                            '&.Mui-focused + .download-icon': {
                                opacity: 0,
                            },
                            '&.Mui-focused': {
                                marginLeft: 0,
                            },
                            '&.MuiFormLabel-filled': {
                                marginLeft: 0,
                            },
                        },
                    },
                },
            },
        },
    },
    palette: {
        primary: {main: colors.default.sentinelBlue},
        background: {
            default: colors.default.sentinelExtraLightGrey,
        },
    },

    typography: {
        h1: {
            color: colors.default.sentinelBlue,
            fontSize: `${26 / 16}rem`, // font size in px from Figma divided by base font of 16
            fontWeight: 500,
            letterSpacing: '0.3px',
            lineHeight: 1.2,
            marginBottom: '0.7rem',
        },
        h2: {
            color: colors.default.sentinelBlue,
            fontSize: `${24 / 16}rem`,
            fontWeight: 500,
            letterSpacing: '0.3px',
            lineHeight: 1.2,
            marginBottom: '0.4rem',
        },
        h3: {
            color: colors.default.sentinelBlue,
            fontSize: `${22 / 16}rem`,
            fontWeight: 500,
            letterSpacing: '0.3px',
            lineHeight: 1.1,
            marginBottom: '0.4rem',
        },
        h4: {
            color: colors.default.sentinelBlue,
            fontSize: `${20 / 16}rem`,
            fontWeight: 500,
            letterSpacing: '0.35px',
            lineHeight: 1.1,
        },
        h5: {
            color: colors.default.sentinelBlue,
            fontSize: `${18 / 16}rem`,
            fontWeight: 500,
            letterSpacing: '0.5px',
            lineHeight: 1,
        },
        h6: {
            color: colors.default.sentinelBlue,
            fontSize: `${1}rem`,
            fontWeight: 500,
            letterSpacing: '0.5px',
            lineHeight: 1,
        },
        subtitle1: {
            fontSize: `${1}rem`,
            fontWeight: 400,
            letterSpacing: '0.44px',
            lineHeight: 1,
        },
        subtitle2: {
            fontSize: `${14 / 16}rem`,
            fontWeight: 500,
            letterSpacing: '0.44px',
            lineHeight: 1,
        },
        body1: {
            fontSize: `${1}rem`,
            fontWeight: 400,
            letterSpacing: '0.44px',
            lineHeight: 1,
        },
        body2: {
            fontSize: `${14 / 16}rem`,
            fontWeight: 400,
            letterSpacing: '0.44px',
            lineHeight: 1,
        },
        button: {
            fontSize: `${1}rem`,
            fontWeight: 500,
            letterSpacing: '0.5px',
            lineHeight: 24 / 16,
        },
        caption: {
            color: grey['800'],
            fontSize: `${14 / 16}rem`,
            fontWeight: 500,
            letterSpacing: '0.15px',
            lineHeight: 1.3,
        },
        error: {
            color: colors.default.errorRed,
            fontSize: '0.85rem',
        },
        background: {
            color: grey[400],
        },
        label: {
            color: colors.default.muiGrey,
            fontSize: '1rem',
        },
    },
    intensity: {
        cyan: '#129490',
        white: '#FFF',
        green: '#70B77E',
        yellow: '#FABC2A',
        orange: '#FF7B5C',
        red: '#CF525B',
        grey: '#B3B3B3',
        transparent: '#0000',
    },
});

declare module '@mui/material/styles' {
    interface TypographyVariants {
        error: React.CSSProperties;
        background: React.CSSProperties;
        label: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        error?: React.CSSProperties;
        background?: React.CSSProperties;
        label?: React.CSSProperties;
    }

    interface Theme {
        intensity: {
            cyan: string;
            white: string;
            green: string;
            yellow: string;
            orange: string;
            red: string;
            grey: string;
            transparent: string;
        };
    }

    interface ThemeOptions {
        intensity?: {
            cyan?: string;
            white?: string;
            green?: string;
            yellow?: string;
            orange?: string;
            red?: string;
            grey?: string;
            transparent?: string;
        };
    }
}

export default theme;

import { useQuery } from 'react-query';

import { Changeset, projectApi } from '../api/sentinel';

type UseProjectChangeHistoryResult = {
    isLoading: boolean;
    error: Error | null;
    data?: Changeset[];
};

const useProjectChangeHistory = (
    projectId?: string,
): UseProjectChangeHistoryResult => {
    return useQuery<Changeset[], Error>(
        ['projectChangeHistory', projectId],
        async ({ signal }) => {
            return projectId
                ? await projectApi.getProjectChangesets(
                      { projectId },
                      { signal },
                  )
                : [];
        },
    );
};

export default useProjectChangeHistory;

import CollectFieldData from '../collectors/CollectFieldData';
import useCategoryCaption from './useCategoryCaption';
import useDataPointDefinitions from './useDataPointDefinitions';

export interface ProjectDataPointDefinition {
    category: string | undefined;
    categoryCaption: string | undefined;
    items: CollectFieldData[];
}

const useDataPointConfiguration = () => {
    const categoryCaptionData = useCategoryCaption().data;
    const dataPointDefinitionsAndConfig = useDataPointDefinitions({});
    const dataPointDefinitionData = dataPointDefinitionsAndConfig.allDataPoints;

    const categories = [
        ...new Set(
            dataPointDefinitionData //Project level dataPoints are intentionaly filter out, so they don't appear in the Project DataPoints Configuration.
                .filter(
                    item =>
                        !!item.dataPoint.category &&
                        item.dataPoint.category != 'PROJECT',
                )
                .map(item => item.dataPoint.category),
        ),
    ];

    const data: ProjectDataPointDefinition[] = [];
    categories.map(category => {
        const categoryCaption = categoryCaptionData.find(
            caption => caption.id == category,
        )?.title;
        const collectFieldData = dataPointDefinitionData.filter(
            item => item.dataPoint.category == category,
        );
        const value: ProjectDataPointDefinition = {
            category: category ?? '',
            categoryCaption: categoryCaption ?? '',
            items: collectFieldData,
        };
        data.push(value);
    });

    return {
        data,
    };
};

export default useDataPointConfiguration;

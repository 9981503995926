import BugReportIcon from '@mui/icons-material/BugReport';
import Button from '@mui/material/Button';
import CodeIcon from '@mui/icons-material/Code';
import Grid from '@mui/material/Grid';
import LoopIcon from '@mui/icons-material/Loop';
import NatureIcon from '@mui/icons-material/Nature';
import Typography from '@mui/material/Typography';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import {
    FormProvider,
    Resolver,
    useForm,
    UseFormProps,
    UseFormReturn,
} from 'react-hook-form';
import DateInput from '../../common/formContextBoundControls/DateInput';
import TextFieldInput from '../../common/formContextBoundControls/TextFieldInput';
import SwitchInput from '../../common/formContextBoundControls/SwitchInput';
import { useTranslation } from 'react-i18next';
import { generateValidationSchema } from '../services/validationSchema';
import Divider from '@mui/material/Divider';
import ReadOnlyTooltip from '../../common/ReadOnlyTooltip';
import { Fragment, MouseEventHandler, useEffect } from 'react';
import MetricCategoryForm, {
    MetricData,
    MetricsCategoryData,
    SelectMetricData,
} from './MetricCategoryForm';
import { SprintFormModel } from '../services/sprintMapper';
import {
    SelectMetricSpec,
    MetricSpec,
} from '../../services/useProvidedMetricSpecs';
import addDays from 'date-fns/addDays';
import ChangeHistoryButton from '../../changeset/ChangeHistoryButton';
import CollectFieldData from '../../collectors/CollectFieldData';
import { findFieldData } from '../../services/useDataPointDefinitions';
import Collector from '../../collectors/Collector';
import { authService } from '../../authentication/useAuth';

const SprintForm = ({
    model,
    save,
    formDirtyStatusCallback,
    providedMetricSpecs,
    isPreviousSprintCompleted,
    navigateBack,
    fieldsData,
}: {
    model: Partial<SprintFormModel>;
    save: (m: SprintFormModel) => Promise<void>;
    formDirtyStatusCallback: (value: boolean) => void;
    providedMetricSpecs: Record<string, MetricSpec>;
    isPreviousSprintCompleted: (
        endDate: Date,
        currentSprintId: number | undefined,
    ) => boolean;
    navigateBack: MouseEventHandler;
    fieldsData: CollectFieldData[];
}) => {
    const allowedSprintMetrics: string[] = Array.from(
        fieldsData.map(field => field.dataPoint.id).values(),
    );

    const validationSchema = generateValidationSchema(
        providedMetricSpecs,
        fieldsData.filter(f =>
            ['INTEGER', 'DECIMAL', 'PERCENTAGE', 'ENUMERATION'].includes(
                f.dataPoint.type,
            ),
        ),
    );
    const { t } = useTranslation();
    const translation = {
        commentary: t('sprintForm_commentary_label'),
        endDate: t('global_endDate'),
        endDateToolTip: t('sprintForm_endDate_tooltip'),
        metricsInfoIntro: t('sprintForm_metricsInfo_intro'),
        metricsInfoTitle: t('sprintForm_metricsInfo_title'),
        saveSprint: t('sprintForm_saveSprint_label'),
        sprintName: t('sprintForm_sprint_name'),
        sprintGoal: t('sprintForm_sprintGoal_label'),
        sprintGoalHelper: t('sprintForm_sprintGoal_HelperText'),
        sprintGoalSucceeded: t('sprintForm_sprintGoalSucceeded_label'),
        sprintGoalSucceededTooltip: t('sprintForm_sprintGoalSucceeded_tooltip'),
        sprintGoalTooltip: t('sprintForm_sprintGoal_tooltip'),
        startDate: t('global_startDate'),
        sprintNameToolTip: t('sprintForm_sprintName_tooltip'),
        startDateToolTip: t('sprintForm_startDate_tooltip'),
        sprintStatus: t('sprintForm_sprintStatus_label'),
        sprintStatusTooltip: t('sprintForm_sprintStatus_tooltip'),
        sprintNamePlaceholder: t('sprintForm_sprintName_placeholder'),
        sprintCommentaryTooltip: t('sprintForm_sprintCommentary_tooltip'),
    };

    const metricsDataTemplate: Record<string, MetricsCategoryData> = {
        AGILITY: { icon: LoopIcon, metrics: {} },
        QUALITY: { icon: BugReportIcon, metrics: {} },
        SOFTWARE_HABITABILITY: { icon: CodeIcon, metrics: {} },
        ENVIRONMENT: { icon: NatureIcon, metrics: {} },
        RELEASE: { icon: NewReleasesIcon, metrics: {} },
    };

    const metricDataFromSpec = (spec: MetricSpec): MetricData => {
        const metricData: MetricData = {} as MetricData;

        switch (spec.type) {
            case 'INTEGER':
                metricData.type = 'integer';
                break;
            case 'DECIMAL':
                metricData.type = 'decimal';
                break;
            case 'TEAM_MORALE':
                metricData.type = 'morale';
                break;
            default:
                metricData.type = 'select';
                (metricData as SelectMetricData).options = (
                    spec as SelectMetricSpec
                ).options;
                break;
        }
        metricData.valueConstraint = spec.valueConstraint;

        return metricData;
    };

    const metricsData = Object.entries(providedMetricSpecs)
        .filter(([key]) => allowedSprintMetrics.includes(key))
        .filter(([key]) => key !== 'iterationGoalSucceeded')
        .reduce((a, [key, spec]) => {
            a[spec.category].metrics[key] = metricDataFromSpec(spec);
            return a;
        }, metricsDataTemplate);

    const form: UseFormReturn<SprintFormModel, UseFormProps> =
        useForm<SprintFormModel>({
            defaultValues: model,
            resolver: yupResolver(
                validationSchema,
            ) as Resolver<SprintFormModel>,
            mode: 'onBlur',
            reValidateMode: 'onChange',
        });

    const [text, setText] = useState('');

    useEffect(() => {
        formDirtyStatusCallback(form.formState.isDirty);
    }, [form.formState.isDirty, formDirtyStatusCallback]);

    useEffect(() => {
        // naive implementation, will pick the first error, good enough
        const keys = Object.keys(form.formState.errors);
        if (keys.length) {
            form.setFocus(keys[0]);
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [form.formState.errors, form.setFocus]);

    const endDate = form.watch('endDate');
    const sprintTitle = form.watch('name');

    const isSprintEndDateInFuture = (
        sprintEndDate: Date | undefined,
        currentDate: Date,
    ): boolean => {
        if (!sprintEndDate) return false;
        const sprintEndDateMidnight = sprintEndDate.setHours(0, 0, 0, 0);
        const currentDateMidnight = currentDate.setHours(0, 0, 0, 0);
        return sprintEndDateMidnight > currentDateMidnight;
    };

    const disableSprintCompletion =
        model?.isSprintCompleted ||
        isSprintEndDateInFuture(endDate, new Date()) ||
        !isPreviousSprintCompleted(endDate, model.sprintId);

    const getMinDate = () => {
        return addDays(new Date(form.watch('startDate')), 1);
    };

    return (
        <FormProvider {...form}>
            <ReadOnlyTooltip childDisabled={!authService.hasEditingRights()}>
                <form onSubmit={form.handleSubmit(save)}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography
                                component="h1"
                                variant="h1"
                                id="sentinel-sprint-number"
                                data-testid="sentinel-sprint-number"
                            >
                                {sprintTitle}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <ChangeHistoryButton onClick={navigateBack} />
                        </Grid>
                    </Grid>
                    <Typography component="h2" variant="h2">
                        {translation.metricsInfoTitle}
                    </Typography>
                    <Typography paragraph variant="body2">
                        {translation.metricsInfoIntro}
                    </Typography>

                    <Grid container spacing={4} sx={{ marginBottom: '2rem' }}>
                        <Grid item xs={12} sm={12}>
                            <TextFieldInput
                                label={translation.sprintName}
                                name="name"
                                tooltip_title={translation.sprintNameToolTip}
                                disabled={!authService.hasEditingRights()}
                                placeholder={translation.sprintNamePlaceholder}
                            />
                        </Grid>

                        <Grid item xs={12} sm={5}>
                            <DateInput
                                label={translation.startDate}
                                name="startDate"
                                required
                                tooltip_title={translation.startDateToolTip}
                                disabled={!authService.hasEditingRights()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} />
                        <Grid item xs={12} sm={5}>
                            <DateInput
                                label={translation.endDate}
                                name="endDate"
                                required
                                tooltip_title={translation.endDateToolTip}
                                disabled={!authService.hasEditingRights()}
                                minDate={getMinDate()}
                            />
                        </Grid>

                        <Grid item xs={12} sm={7}>
                            <TextFieldInput
                                helper_text={translation.sprintGoalHelper}
                                label={translation.sprintGoal}
                                name="sprintGoal"
                                multiline
                                onChange={e => setText(e.currentTarget.value)}
                                value={text}
                                inputProps={{ maxLength: 140 }}
                                tooltip_title={translation.sprintGoalTooltip}
                                disabled={!authService.hasEditingRights()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            {fieldsData.some(
                                item =>
                                    item.dataPoint.id ===
                                    'iterationGoalSucceeded',
                            ) && (
                                <Collector
                                    hasRole={authService.isAllowed}
                                    fieldData={findFieldData(
                                        fieldsData,
                                        'iterationGoalSucceeded',
                                    )}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextFieldInput
                                name="commentary"
                                label={translation.commentary}
                                data-testid="commentary"
                                multiline
                                rows={5}
                                textAreaStyle={true}
                                tooltip_title={
                                    translation.sprintCommentaryTooltip
                                }
                            />
                        </Grid>
                    </Grid>

                    {Object.entries(metricsData)
                        .filter(
                            ([category, metrics]) =>
                                category &&
                                Object.entries(metrics.metrics).length > 0,
                        )
                        .map(([category, metrics]) => (
                            <Fragment key={category}>
                                <Divider sx={{ marginBottom: '1em' }} />
                                <MetricCategoryForm
                                    category={category}
                                    metrics={metrics}
                                    fieldsData={fieldsData}
                                />
                            </Fragment>
                        ))}

                    <Grid
                        container
                        direction="row"
                        spacing={4}
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={4}>
                            <SwitchInput
                                name="isSprintCompleted"
                                label={translation.sprintStatus}
                                tooltip_title={translation.sprintStatusTooltip}
                                disabled={
                                    disableSprintCompletion ||
                                    !authService.hasEditingRights()
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                disabled={
                                    form.formState.isSubmitting ||
                                    !authService.hasEditingRights()
                                }
                                fullWidth
                                type="submit"
                                variant="contained"
                                data-testid="save-sprint-button"
                            >
                                {translation.saveSprint}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </ReadOnlyTooltip>
        </FormProvider>
    );
};
export default SprintForm;

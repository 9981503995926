// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { Page } from '../models/index';
import { PageFromJSON, PageToJSON } from '../models/index';

export interface GetPeriodsAsFileRequest {
    projectIds?: Array<string>;
    accept?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface GetPeriodsAsPayloadRequest {
    projectIds?: Array<string>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 *
 */
export class PeriodApi extends runtime.BaseAPI {
    /**
     */
    async getPeriodsAsFileRaw(
        requestParameters: GetPeriodsAsFileRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Page>> {
        const queryParameters: any = {};

        if (requestParameters.projectIds) {
            queryParameters['projectIds'] = requestParameters.projectIds.join(
                runtime.COLLECTION_FORMATS['csv'],
            );
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(
                runtime.COLLECTION_FORMATS['csv'],
            );
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (
            requestParameters.accept !== undefined &&
            requestParameters.accept !== null
        ) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/periods/download`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            PageFromJSON(jsonValue),
        );
    }

    /**
     */
    async getPeriodsAsFile(
        requestParameters: GetPeriodsAsFileRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Page> {
        const response = await this.getPeriodsAsFileRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getPeriodsAsPayloadRaw(
        requestParameters: GetPeriodsAsPayloadRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Page>> {
        const queryParameters: any = {};

        if (requestParameters.projectIds) {
            queryParameters['projectIds'] = requestParameters.projectIds.join(
                runtime.COLLECTION_FORMATS['csv'],
            );
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(
                runtime.COLLECTION_FORMATS['csv'],
            );
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/periods`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            PageFromJSON(jsonValue),
        );
    }

    /**
     */
    async getPeriodsAsPayload(
        requestParameters: GetPeriodsAsPayloadRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Page> {
        const response = await this.getPeriodsAsPayloadRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}

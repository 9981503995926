// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     *
     * @type {boolean}
     * @memberof Sort
     */
    sorted?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Sort
     */
    unsorted?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Sort
     */
    empty?: boolean;
}

/**
 * Check if a given object implements the Sort interface.
 */
export function instanceOfSort(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SortFromJSON(json: any): Sort {
    return SortFromJSONTyped(json, false);
}

export function SortFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): Sort {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        sorted: !exists(json, 'sorted') ? undefined : json['sorted'],
        unsorted: !exists(json, 'unsorted') ? undefined : json['unsorted'],
        empty: !exists(json, 'empty') ? undefined : json['empty'],
    };
}

export function SortToJSON(value?: Sort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        sorted: value.sorted,
        unsorted: value.unsorted,
        empty: value.empty,
    };
}

// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { DataPointDefinition } from '../models/index';
import {
    DataPointDefinitionFromJSON,
    DataPointDefinitionToJSON,
} from '../models/index';

export interface GetProjectDataPointDefinitionsRequest {
    projectId: string;
}

export interface GetSprintDataPointDefinitionsRequest {
    projectId: string;
    sprintId: number;
}

export interface SetProjectDataPointDefinitionsRequest {
    projectId: string;
    dataPointDefinition: Array<DataPointDefinition>;
}

/**
 *
 */
export class DataPointsApi extends runtime.BaseAPI {
    /**
     */
    async getAllDataPointDefinitionsRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<DataPointDefinition>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/data-points`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            jsonValue.map(DataPointDefinitionFromJSON),
        );
    }

    /**
     */
    async getAllDataPointDefinitions(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<DataPointDefinition>> {
        const response =
            await this.getAllDataPointDefinitionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectDataPointDefinitionsRaw(
        requestParameters: GetProjectDataPointDefinitionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<DataPointDefinition>>> {
        if (
            requestParameters.projectId === null ||
            requestParameters.projectId === undefined
        ) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter requestParameters.projectId was null or undefined when calling getProjectDataPointDefinitions.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects/{projectId}/data-points`.replace(
                    `{${'projectId'}}`,
                    encodeURIComponent(String(requestParameters.projectId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            jsonValue.map(DataPointDefinitionFromJSON),
        );
    }

    /**
     */
    async getProjectDataPointDefinitions(
        requestParameters: GetProjectDataPointDefinitionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<DataPointDefinition>> {
        const response = await this.getProjectDataPointDefinitionsRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getSprintDataPointDefinitionsRaw(
        requestParameters: GetSprintDataPointDefinitionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<DataPointDefinition>>> {
        if (
            requestParameters.projectId === null ||
            requestParameters.projectId === undefined
        ) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter requestParameters.projectId was null or undefined when calling getSprintDataPointDefinitions.',
            );
        }

        if (
            requestParameters.sprintId === null ||
            requestParameters.sprintId === undefined
        ) {
            throw new runtime.RequiredError(
                'sprintId',
                'Required parameter requestParameters.sprintId was null or undefined when calling getSprintDataPointDefinitions.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects/{projectId}/sprints/{sprintId}/datapoints`
                    .replace(
                        `{${'projectId'}}`,
                        encodeURIComponent(String(requestParameters.projectId)),
                    )
                    .replace(
                        `{${'sprintId'}}`,
                        encodeURIComponent(String(requestParameters.sprintId)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            jsonValue.map(DataPointDefinitionFromJSON),
        );
    }

    /**
     */
    async getSprintDataPointDefinitions(
        requestParameters: GetSprintDataPointDefinitionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<DataPointDefinition>> {
        const response = await this.getSprintDataPointDefinitionsRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async setProjectDataPointDefinitionsRaw(
        requestParameters: SetProjectDataPointDefinitionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<DataPointDefinition>>> {
        if (
            requestParameters.projectId === null ||
            requestParameters.projectId === undefined
        ) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter requestParameters.projectId was null or undefined when calling setProjectDataPointDefinitions.',
            );
        }

        if (
            requestParameters.dataPointDefinition === null ||
            requestParameters.dataPointDefinition === undefined
        ) {
            throw new runtime.RequiredError(
                'dataPointDefinition',
                'Required parameter requestParameters.dataPointDefinition was null or undefined when calling setProjectDataPointDefinitions.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects/{projectId}/data-points`.replace(
                    `{${'projectId'}}`,
                    encodeURIComponent(String(requestParameters.projectId)),
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.dataPointDefinition.map(
                    DataPointDefinitionToJSON,
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            jsonValue.map(DataPointDefinitionFromJSON),
        );
    }

    /**
     */
    async setProjectDataPointDefinitions(
        requestParameters: SetProjectDataPointDefinitionsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<DataPointDefinition>> {
        const response = await this.setProjectDataPointDefinitionsRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}

import { Change } from '../api/sentinel';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import TextFieldDisplay from '../common/formContextBoundControls/TextFieldDisplay';
import Typography from '@mui/material/Typography';
import { FC, useState } from 'react';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function ChangeView({ changes }: { changes: Change[] }) {
    const { t } = useTranslation();
    const [isRowExpanded, setIsRowExpanded] = useState(new Map());
    const [rowExpandable, setRowExpandable] = useState(new Map());

    const rowStateMap = new Map();

    const onOverflowEvaluated = (
        rowId: GridRowId | undefined,
        fieldName: string | undefined,
        isOverflowing: boolean,
    ) => {
        if (!fieldName) {
            return;
        }
        const rowOverflowState = { [fieldName]: isOverflowing };
        rowStateMap.set(rowId, {
            ...rowStateMap.get(rowId),
            ...rowOverflowState,
        });

        const rowMap = new Map(rowExpandable);

        if (
            !(
                rowMap.has(rowId) &&
                rowMap.get(rowId)[fieldName] === isOverflowing
            )
        ) {
            if (
                Object.keys(rowStateMap.get(rowId)).reduce(
                    (a: boolean, b: string) => a || rowStateMap.get(rowId)[b],
                    false,
                )
            ) {
                setRowExpandable(rowMap.set(rowId, rowStateMap.get(rowId)));
            }
        }
    };

    function canExpand(id: GridRowId) {
        return (
            rowExpandable.has(id) &&
            Object.keys(rowExpandable.get(id)).reduce(
                (result, currentFieldName) =>
                    result || rowExpandable.get(id)[currentFieldName],
                false,
            )
        );
    }

    const commonAttributes = {
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
    };

    const field = (
        fieldName: string,
        options?: Partial<GridColDef>,
    ): GridColDef => {
        return {
            field: fieldName,
            headerName: t(`change_${fieldName}_label`),
            ...commonAttributes,
            ...options,
        };
    };

    const toggleExpansion = (rowId: GridRowId, fieldName: string) => {
        const isExpandedRowMap = new Map(isRowExpanded);
        if (
            !isExpandedRowMap.has(rowId) &&
            rowExpandable.get(rowId)[fieldName]
        ) {
            isExpandedRowMap.set(rowId, false);
        }
        isExpandedRowMap.set(rowId, !isExpandedRowMap.get(rowId));
        setIsRowExpanded(isExpandedRowMap);
    };

    interface ExpandableButtonProps {
        isExpanded: boolean;
        toggleExpansion: () => void;
        t: (key: string) => string;
    }

    function getBox(label: string) {
        return (
            <Box
                aria-hidden={true}
                sx={{
                    bottom: 0,
                    fontSize: '0.7rem',
                }}
            >
                {t(label)}
            </Box>
        );
    }

    const ExpandableButton: FC<ExpandableButtonProps> = ({
        isExpanded,
        toggleExpansion,
        t,
    }) => (
        <Button
            variant="outlined"
            onClick={toggleExpansion}
            aria-label={
                isExpanded
                    ? t('change_toggle_button_showLess')
                    : t('change_toggle_button_showMore')
            }
            sx={{
                width: 'fit-content',
            }}
        >
            {isExpanded
                ? getBox('change_toggle_button_showLess')
                : getBox('change_toggle_button_showMore')}
        </Button>
    );

    function canCellExpand(rowId: GridRowId, fieldName: string) {
        return rowExpandable.has(rowId) && rowExpandable.get(rowId)[fieldName];
    }

    function getStack(id: GridRowId, fieldValue: string, fieldName: string) {
        return (
            <Stack
                sx={{
                    height: '100%',
                    width: '100%',
                    justifyContent: 'space-between',
                    position: 'relative',
                    paddingBottom: '8px',
                }}
            >
                <TextFieldDisplay
                    value={fieldValue}
                    rowsNumberWithoutScroll={5}
                    isExpanded={isRowExpanded.get(id)}
                    isRowExpandable={canExpand(id)}
                    rowId={id}
                    name={fieldName}
                    onOverflowEvaluated={onOverflowEvaluated}
                />
                {canCellExpand(id, fieldName) && (
                    <ExpandableButton
                        isExpanded={isRowExpanded.get(id)}
                        toggleExpansion={() => toggleExpansion(id, fieldName)}
                        t={t}
                    />
                )}
            </Stack>
        );
    }

    function getFieldDefinition(fieldName: string) {
        return field(fieldName, {
            flex: 1.5,
            maxWidth: 700,
            hideable: false,
            filterable: true,
            renderCell: params =>
                getStack(params.id, params.value, params.field),
        });
    }

    const columns: GridColDef[] = [
        field('fieldName', {
            width: 350,
            maxWidth: 350,
            hideable: false,
            filterable: true,
            valueGetter: params => t(params.value),
            renderCell: params => (
                <Stack
                    direction="row"
                    sx={{
                        height: '100%',
                        width: '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        cursor: 'default',
                    }}
                >
                    <Typography>{params.value}</Typography>
                </Stack>
            ),
        }),
        getFieldDefinition('newValue'),
        getFieldDefinition('oldValue'),
    ];

    return (
        <DataGrid
            getRowHeight={() => 'auto'}
            density={'standard'}
            getRowId={(change: Change) => JSON.stringify(change)}
            columns={columns}
            rows={changes ? changes : []}
            disableRowSelectionOnClick
            hideFooter={changes.length <= 20}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'fieldName', sort: 'asc' }],
                },
            }}
        />
    );
}

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
} from '@mui/material';
import CheckBoxList from '../../common/formContextBoundControls/CheckBoxList';
import useDataPointConfiguration from '../../services/useDataPointConfiguration';
import useDashboardData from '../../dashboard/useDashboardData';

import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '../../theme/theme';
import { authService } from '../../authentication/useAuth';

const ProjectDataPointsConfiguration = (props: {
    name: string;
    projectId?: string;
}) => {
    const { data } = useDataPointConfiguration();

    const dashboardResult = useDashboardData(props.projectId);

    const { t: translate } = useTranslation();

    const dataPointsConfiguration = data ?? [];

    const hasSprints = (): boolean => {
        return (
            !!dashboardResult.data?.sprints &&
            dashboardResult.data.sprints.length > 0
        );
    };

    return (
        <Accordion>
            <AccordionSummary
                sx={{ minHeight: '1px' }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                {translate('projectForm_dataPoint_configuration_title')}
            </AccordionSummary>
            <AccordionDetails>
                <Box mb="24px" fontSize="14px" color={theme.palette.grey[600]}>
                    {translate('projectForm_dataPoint_configuration_subtitle')}
                </Box>
                <Grid container spacing={4}>
                    {dataPointsConfiguration
                        .filter(
                            dataPointsConfiguration =>
                                !dataPointsConfiguration.items.some(
                                    member => member.dataPoint.type == 'MEMBER',
                                ),
                        )
                        .map(dataPointConfiguration => (
                            <Grid
                                item
                                key={dataPointConfiguration.category}
                                xs={12}
                                sm={6}
                            >
                                <CheckBoxList
                                    name={props.name}
                                    label={
                                        dataPointConfiguration.categoryCaption ??
                                        ''
                                    }
                                    items={dataPointConfiguration.items.map(
                                        item => ({
                                            value: String(item.dataPoint.id),
                                            label: translate(
                                                item.caption.title ?? '',
                                            ),
                                        }),
                                    )}
                                    disabled={
                                        dashboardResult.isLoading ||
                                        !authService.hasLeadRights() ||
                                        hasSprints()
                                    }
                                />
                            </Grid>
                        ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default ProjectDataPointsConfiguration;

import * as yup from 'yup';
import i18n from '../../i18n/i18n';
import { mapObject } from '../../utils/util';
import { startOfDay } from 'date-fns';
import { MetricSpec } from '../../services/useProvidedMetricSpecs';
import CollectFieldData from '../../collectors/CollectFieldData';
import generateValidations from '../../collectors/ValidatorSchemaFactory';

const t = i18n.t;
const endDate_isRequired = t('sprintValidationSchema_endDate_isRequired');
const sprintValidationSchema_endDate_minDate = t(
    'sprintValidationSchema_endDate_minDate',
);
const sprintValidationSchema_startDate_maxDate = t(
    'sprintValidationSchema_startDate_maxDate',
);
const startDate_isRequired = t('sprintValidationSchema_startDate_isRequired');
const sprintGoal_maxCharacters = t(
    'sprintValidationSchema_sprintGoal_maxCharacters',
);

const validationSchema = {
    commentary: yup.string(),
    endDate: yup
        .date()
        .typeError(endDate_isRequired)
        .required(endDate_isRequired)
        .transform(startOfDay)
        .min(yup.ref('startDate'), sprintValidationSchema_endDate_minDate)
        .test(
            'is-same_as_startDate',
            sprintValidationSchema_endDate_minDate,
            function (value) {
                const startDate = this.resolve(yup.ref('startDate')) as Date;
                return validateDate(value, startDate);
            },
        ),
    iterationGoalSucceeded: yup.string(),
    name: yup.string(),
    projectId: yup.string(),
    sprintGoal: yup
        .string()
        .max(140, sprintGoal_maxCharacters)
        .test(
            'is-required_when_goalSuccess',
            'Sprint Goal is required',
            function (value): boolean {
                const iterationGoalSucceeded = this.resolve(
                    yup.ref('iterationGoalSucceeded'),
                ) as string;

                return !iterationGoalSucceeded ? true : !!value;
            },
        ),
    sprintId: yup.number(),
    sprintNo: yup.number(),
    startDate: yup
        .date()
        .typeError(startDate_isRequired)
        .required(startDate_isRequired)
        .transform(startOfDay)
        .max(yup.ref('endDate'), sprintValidationSchema_startDate_maxDate)
        .test(
            'is-same_as_endDate',
            sprintValidationSchema_startDate_maxDate,
            function (value) {
                const endDate = this.resolve(yup.ref('endDate')) as Date;
                return validateDate(value, endDate);
            },
        ),
};

const validateDate = (value: Date | undefined, date: Date) => {
    return date.getTime() !== value?.getTime();
};

export const generateValidationSchema = (
    providedMetricSpecs: {
        [key: string]: MetricSpec;
    },
    fieldsData: CollectFieldData[],
) => {
    return yup.object({
        ...validationSchema,
        ...mapObject(providedMetricSpecs, spec => spec.resolver),
        ...generateValidations(fieldsData),
    });
};

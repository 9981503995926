import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Metric } from '../api/sentinel';
import ModalAppBar from '../common/ModalAppBar';
import Spinner from '../common/Spinner';
import { ValidationError } from '../services/errors/ValidationError';
import theme from '../theme/theme';
import SprintForm from './components/SprintForm';
import { generateProvidedMetricsRequest } from './services/metricsMapper';
import { dtoToForm, formToDto, SprintFormModel } from './services/sprintMapper';
import useSprint from './useSprint';
import useProvidedMetricSpecs from '../services/useProvidedMetricSpecs';
import useDataPointDefinitions, {DataPointDefinitionRequest} from '../services/useDataPointDefinitions';

const Sprint = ({ isCreating = false }: { isCreating?: boolean }) => {
    const { t: translate } = useTranslation();

    const params = useParams();
    const projectId = params.projectId ?? '';
    const sprintId = parseInt(params.sprintId ?? '0');
    const navigate = useNavigate();
    const [isFormDirty, setIsFormDirty] = useState(false);

    const returnToDashboard = (newSprintId?: number) => {
        const id = !isCreating ? sprintId : newSprintId ? newSprintId : '';
        const path = `/Dashboard/${projectId}/${id}`;
        navigate(path);
    };

    const {
        isLoading,
        sprint,
        metrics,
        saveSprint,
        isPreviousSprintCompleted,
    } = useSprint(projectId, sprintId, isCreating);

    const { sprintProvidedMetricSpecs, isLoadingSpecs } =
        useProvidedMetricSpecs();

    const success = (newSprintId: number) => {
        toast.success(translate('sprint_saved_text'), {
            toastId: 'sentinel-toast-sprint-success',
        });
        returnToDashboard(newSprintId);
    };

    const failure = (error: Error) => {
        if (error instanceof ValidationError) {
            error.errors.forEach((errorDetails, index) => {
                toast.error(translate(errorDetails.message ?? ''), {
                    toastId: `backend-validation-error-${index}`,
                });
            });
        }
        toast.error(translate('sprint_not_saved_text'), {
            toastId: 'sentinel-toast-sprint-failure',
        });
    };

    const save = async (data: SprintFormModel) => {
        try {
            const dto = formToDto(data);
            const providedMetricsRequest = generateProvidedMetricsRequest(
                data,
                sprintProvidedMetricSpecs,
            );
            const newSprintId = await saveSprint(dto, providedMetricsRequest);
            success(newSprintId);
        } catch (error: unknown) {
            failure(error as Error);
        }
    };


    const request : DataPointDefinitionRequest = (isCreating) ? {projectId} : {projectId, periodId: sprintId};
    const fieldDataDefinitions = useDataPointDefinitions(request);
    const toolbarProps = {
        title: isCreating
            ? translate('sprint_create_title')
            : translate('sprint_edit_title'),
        dialogTitle: isCreating
            ? translate('sprint_forget_title')
            : translate('sprint_unsavedChange_title'),
        dialogBody: isCreating
            ? translate('sprint_confirm_remove')
            : translate('sprint_confirm_discardChange'),
        onClose: () => {
            returnToDashboard();
        },
    };

    if (isLoading || isLoadingSpecs || fieldDataDefinitions.isLoading) {
        return <Spinner open={true} />;
    }

    const formMetrics = Object.fromEntries(
        Object.entries(metrics).map(([key, value]) => {
            const val =
                isCreating &&
                sprintProvidedMetricSpecs[key]?.category !== 'ENVIRONMENT'
                    ? undefined
                    : value;
            return [key, val];
        }),
    );

    const model = dtoToForm(
        sprint!,
        formMetrics as Record<string, Metric>,
        sprintProvidedMetricSpecs,
    );

    return (
        <Dialog fullScreen open={true}>
            <ModalAppBar
                showWarnings={isFormDirty}
                title={toolbarProps.title}
                dialogTitle={toolbarProps.dialogTitle}
                dialogBody={toolbarProps.dialogBody}
                onClose={toolbarProps.onClose}
            />

            <Container
                maxWidth={false}
                sx={{
                    backgroundColor: theme.palette.grey['100'],
                }}
            >
                <Container
                    maxWidth="md"
                    sx={{
                        backgroundColor: theme.palette.common.white,
                        paddingBottom: '2rem',
                        paddingTop: '2rem',
                    }}
                >
                    <SprintForm
                        save={save}
                        model={model}
                        formDirtyStatusCallback={setIsFormDirty}
                        providedMetricSpecs={sprintProvidedMetricSpecs}
                        isPreviousSprintCompleted={isPreviousSprintCompleted}
                        navigateBack={() =>
                            navigate(
                                `/Project/${projectId}/sprint/${model.sprintId}/changeHistory`,
                            )
                        }
                        fieldsData={fieldDataDefinitions.configuredDataPoints}
                    />
                </Container>
            </Container>
        </Dialog>
    );
};

export default Sprint;

// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Sort } from './Sort';
import { SortFromJSON, SortFromJSONTyped, SortToJSON } from './Sort';

/**
 *
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     *
     * @type {Sort}
     * @memberof Pageable
     */
    sort?: Sort;
    /**
     *
     * @type {number}
     * @memberof Pageable
     */
    offset?: number;
    /**
     *
     * @type {number}
     * @memberof Pageable
     */
    pageNumber?: number;
    /**
     *
     * @type {number}
     * @memberof Pageable
     */
    pageSize?: number;
    /**
     *
     * @type {boolean}
     * @memberof Pageable
     */
    paged?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Pageable
     */
    unpaged?: boolean;
}

/**
 * Check if a given object implements the Pageable interface.
 */
export function instanceOfPageable(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageableFromJSON(json: any): Pageable {
    return PageableFromJSONTyped(json, false);
}

export function PageableFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): Pageable {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        sort: !exists(json, 'sort') ? undefined : SortFromJSON(json['sort']),
        offset: !exists(json, 'offset') ? undefined : json['offset'],
        pageNumber: !exists(json, 'pageNumber')
            ? undefined
            : json['pageNumber'],
        pageSize: !exists(json, 'pageSize') ? undefined : json['pageSize'],
        paged: !exists(json, 'paged') ? undefined : json['paged'],
        unpaged: !exists(json, 'unpaged') ? undefined : json['unpaged'],
    };
}

export function PageableToJSON(value?: Pageable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        sort: SortToJSON(value.sort),
        offset: value.offset,
        pageNumber: value.pageNumber,
        pageSize: value.pageSize,
        paged: value.paged,
        unpaged: value.unpaged,
    };
}

import { useQuery } from 'react-query';
import { dataPointApi, DataPointDefinition } from '../api/sentinel';

const useProjectDataPointDefinitions = (projectId: string) => {
    const dataDataPointDefinitionsData = useQuery<DataPointDefinition[], Error>(
        ['dataDataPointDefinitions', projectId],
        async ({ signal }) =>
            dataPointApi.getProjectDataPointDefinitions(
                { projectId },
                { signal },
            ),
    );

    const dataPoints = dataDataPointDefinitionsData.data ?? [];
    const isLoading = dataDataPointDefinitionsData.isLoading;
    return {
        dataPoints: dataPoints,
        isLoading: isLoading,
    };
};

export default useProjectDataPointDefinitions;

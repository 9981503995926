import { useQuery } from 'react-query';
import { dataPointApi, DataPointDefinition } from '../api/sentinel';

const usePeriodDataPointDefinitions = (projectId: string, periodId: number) => {
    const dataDataPointDefinitionsData = useQuery<DataPointDefinition[], Error>(
        ['dataDataPointDefinitions', projectId, periodId],
        async ({ signal }) =>
            dataPointApi.getSprintDataPointDefinitions(
                { projectId, sprintId: periodId },
                { signal },
            ),
    );

    const dataPoints = dataDataPointDefinitionsData.data ?? [];
    const isLoading = dataDataPointDefinitionsData.isLoading;
    return {
        dataPoints: dataPoints,
        isLoading: isLoading,
    };
};

export default usePeriodDataPointDefinitions;

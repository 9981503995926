import { useParams } from 'react-router-dom';
import Spinner from '../common/Spinner';
import { Container } from '@mui/material';
import useSprintChangeHistory from '../sprint/useSprintChangeHistory';
import { safeParseInt } from '../utils/util';
import ChangesetsPanel from './ChangesetsPanel';
import useSprint from '../sprint/useSprint';
import useProjectData from '../project/useProjectData';

const SprintChangeHistory = () => {
    const { projectId, sprintId } = useParams();
    const history = useSprintChangeHistory(projectId, safeParseInt(sprintId));
    const project = useProjectData(projectId);

    const sprint = useSprint(projectId ?? '', parseInt(sprintId ?? '0'), false);

    if (history.isLoading || sprint.isLoading || project.isLoading) {
        return <Spinner open={true} />;
    }
    if (history.error || sprint.error || project.error) {
        return (
            <>
                Error:{' '}
                {history.error?.message ??
                    sprint.error?.message ??
                    project.error?.message}
            </>
        );
    }

    return (
        <>
            <Container maxWidth={false}>
                <ChangesetsPanel
                    values={history.data}
                    sprint={sprint.sprint}
                    projectId={projectId}
                    projectName={project.formModel.projectName}
                />
            </Container>
        </>
    );
};

export default SprintChangeHistory;

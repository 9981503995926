import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateIcon from '@mui/icons-material/Create';
import RevisionsIcon from '@mui/icons-material/History';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ReadOnlyTooltip from '../../common/ReadOnlyTooltip';
import {useNavigate} from 'react-router-dom';
import theme from '../../theme/theme';
import IconTooltip from '../../common/formContextBoundControls/IconTooltip';
import {Box} from '@mui/material';
import ExportProjectData from '../../export/ExportProjectData';
import {useTranslation} from 'react-i18next';
import {authService} from '../../authentication/useAuth';

export default function NavBar({
                                   projectName,
                                   projectId,
                                   projectCommentary,
                               }: {
    grade?: string;
    projectName?: string;
    projectId: string;
    projectCommentary?: string;
}) {
    const {t} = useTranslation();
    const translation = {
        exportDataLabelName: t('projectList_export_data_label'),
    };

    const projectRevisionsLink = `/Project/${projectId}/changeHistory`;
    const navigate = useNavigate();
    return (
        <>
            <Container
                maxWidth={false}
                disableGutters
                sx={{
                    padding: '8px 3%',
                    backgroundColor: theme.palette.common.white,
                }}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <IconButton
                            href={'/Projects'}
                            title={'back to projects page'}
                            data-testid="backToProjectsButton"
                        >
                            <ArrowBackIcon/>
                        </IconButton>
                        <Typography
                            variant="h1"
                            component="h1"
                            mb="0"
                            ml="10px"
                        >
                            {projectName}
                        </Typography>
                        {projectCommentary && (
                            <IconTooltip
                                title={projectCommentary}
                                placement="right"
                                iconName="announcement"
                                maxWidth="800px"
                                id="projectCommentary"
                            ></IconTooltip>
                        )}
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                    >
                        <ReadOnlyTooltip
                            childDisabled={!authService.hasEditingRights()}
                        >
                            <Button
                                startIcon={<CreateIcon/>}
                                size="small"
                                color="primary"
                                href={`/Project/${projectId}`}
                                disabled={!authService.hasEditingRights()}
                                data-testid="editProjectButton"
                            >
                                Edit project
                            </Button>
                            <Box>|</Box>
                            <Button
                                startIcon={<RevisionsIcon/>}
                                aria-label={t(
                                    'projectDashboard_seeRevisions_ariaLabel',
                                )}
                                onClick={() => navigate(projectRevisionsLink)}
                                size="small"
                                color="primary"
                            >
                                {t('projectDashboard_seeRevisions_link')}
                            </Button>
                            <Box>|</Box>
                            <Box>
                                <ExportProjectData
                                    labelName={translation.exportDataLabelName}
                                    projectIds={[projectId]}
                                    minimumWidth="270px"
                                    projectName={projectName}
                                ></ExportProjectData>
                            </Box>
                        </ReadOnlyTooltip>
                    </Stack>
                </Stack>
            </Container>
        </>
    );
}

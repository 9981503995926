import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { default as csvIcon } from '../assests/csv.svg';
import { default as jsonIcon } from '../assests/json.svg';
import { default as csvIconDisabled } from '../assests/csv_disabled.svg';
import { default as jsonIconDisabled } from '../assests/json_disabled.svg';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import FileDownLoad from '@mui/icons-material/FileDownload';
import theme from '../theme/theme';
import { toast } from 'react-toastify';
import { Project } from '../api/sentinel';
import { DownloadService, ExportRequest } from './services/DownloadProjectData';

const ExportProjectData = ({
    labelName,
    projectIds,
    minimumWidth,
    projects,
    projectName,
}: {
    labelName: string;
    projectIds: string[];
    projects?: Project[];
    minimumWidth: string;
    projectName?: string;
}) => {
    const { t } = useTranslation();
    const translation = {
        exportCSV: t('export_data_csv'),
        exportCSVLabel: t('export_data_csv_label'),
        exportJson: t('export_data_json'),
        exportJsonLabel: t('export_data_Json_label'),
        exportProjectFileName: t('export_all_period_data_project'),
        exportProjectsFileName: t('export_all_period_data_projects'),
        exportDataDownloadFailed: t('export_data_download_failed'),
        exportDataDownloadStarted: t('export_data_download_started'),
    };
    const [initialPosition, setInitialPosition] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [fileName, setFileName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setInitialPosition(true);
    };

    const handleOpen = () => {
        setInitialPosition(false);
    };

    useEffect(() => {
        if (projectName) {
            setFileName(`${translation.exportProjectFileName}${projectName}`);
        } else if (projectIds.length === 1) {
            const projectName = projects?.find(
                p => p.projectId === projectIds[0],
            )?.projectName;
            setFileName(`${translation.exportProjectFileName}${projectName}`);
        } else {
            setFileName(`${translation.exportProjectsFileName}`);
        }
    }, [
        projectIds.length,
        translation.exportProjectFileName,
        translation.exportProjectsFileName,
        projectName,
        projectIds,
        projects,
    ]);

    const changeFileTypeHandler = async (e: SelectChangeEvent<string>) => {
        const formatName = e.target.value;
        setSelectedOption(e.target.value);
        try {
            setIsLoading(true);
            toast.info(translation.exportDataDownloadStarted, {
                toastId: 'export_data_download_started',
            });
            const request = {
                formatName,
                fileName,
            } as ExportRequest;
            if (projectIds?.length !== projects?.length) {
                request.projectIds = projectIds;
            }
            await DownloadService.downloadProjectData(request);
            setIsLoading(false);
        } catch (e) {
            toast.error(translation.exportDataDownloadFailed, {
                toastId: 'export_data_download_failed',
            });
        }
    };

    return (
        <FormControl
            className={`export ${initialPosition ? 'initialPos' : ''}`}
            variant="standard"
            sx={{ m: 1, minWidth: minimumWidth }}
            disabled={isLoading}
        >
            <Box id="exportDataSelect">
                <InputLabel size="normal">{labelName}</InputLabel>
                <FileDownLoad
                    className="download-icon"
                    fontSize={'small'}
                    sx={{
                        mb: '-32px',
                        mr: '10px',
                        color: theme.palette.primary.main,
                    }}
                ></FileDownLoad>
            </Box>
            <Select
                labelId="exportDataSelect"
                data-testid="exportFormatSelect"
                onClose={handleClose}
                onOpen={handleOpen}
                value={selectedOption}
                onChange={changeFileTypeHandler}
            >
                <MenuItem
                    value={translation.exportCSV}
                    data-testid="exportToCSV"
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            gap: '8px',
                            height: '32px',
                            pr: '4px',
                            width: '32px',
                        }}
                    >
                        <img
                            src={isLoading ? csvIconDisabled : csvIcon}
                            width={24}
                            alt={translation.exportCSVLabel}
                        ></img>
                        {translation.exportCSV}
                    </Box>
                </MenuItem>
                <MenuItem
                    value={translation.exportJson}
                    data-testid="exportToJson"
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            gap: '8px',
                            height: '32px',
                            width: '32px',
                            pr: '4px',
                        }}
                    >
                        <img
                            src={isLoading ? jsonIconDisabled : jsonIcon}
                            width={24}
                            alt={translation.exportJsonLabel}
                        ></img>
                        {translation.exportJson}
                    </Box>
                </MenuItem>
            </Select>
        </FormControl>
    );
};

export default ExportProjectData;

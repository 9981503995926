import { t } from 'i18next';
import { useQuery } from 'react-query';
import * as yup from 'yup';
import { ISchema } from 'yup';
import { ProvidedMetricSpec, providedMetricsSpecsApi, Restriction, ValueConstraint } from '../api/sentinel';
import { allowedSprintMetrics } from '../sprint/services/allowedSprintMetrics';

export type Category =
    | 'AGILITY'
    | 'QUALITY'
    | 'SOFTWARE_HABITABILITY'
    | 'ENVIRONMENT'
    | 'RELEASE'
    | 'PROJECT';

export type NumberMetricSpec = {
    type: 'INTEGER' | 'DECIMAL';
    resolver?: ISchema<number>;
};

export type YES_NO = {
    type: 'YES_NO';
    resolver?: ISchema<boolean>;
};

export type SelectMetricSpec = {
    type: Exclude<string, 'INTEGER' | 'DECIMAL'>;
    resolver?: ISchema<string>;
    options: string[];
};

export type MetricSpec = (NumberMetricSpec | SelectMetricSpec | YES_NO ) & { category: Category, restriction?: Restriction, valueConstraint?: ValueConstraint};

const useProvidedMetricSpecs = () => {
    const validNumber = t('global_validationSchema_validNumber');
    const validInteger = t('global_validationSchema_validInteger');
    const errorNumberMin = t('error_number_valid_field_min');
    const errorNumberMax = t('error_number_valid_field_max');

    const checkDecimalMinMax = (
        min: number,
        max: number,
        typeError: string,
    ) => {
        return yup
            .number()
            .transform((value, originalValue) =>
                originalValue === '' ? undefined : value,
            )
            .typeError(typeError)
            .min(min, errorNumberMin + ' ' + min)
            .max(max, errorNumberMax + ' ' + max);
    };

    const checkIntegerMinMax = (
        min: number,
        max: number,
        typeError: string,
    ) => {
        return yup
            .number()
            .transform((value, originalValue) =>
                originalValue === '' ? undefined : value,
            )
            .integer(validInteger)
            .typeError(typeError)
            .min(min, errorNumberMin + ' ' + min)
            .max(max, errorNumberMax + ' ' + max);
    };

    const IntegerOrEmptyString = ({ min, max }: ValueConstraint) =>
        checkIntegerMinMax(min!, max!, validNumber).nullable();

    const PercentageOrEmptyString = ({ min, max }: ValueConstraint) =>
        checkDecimalMinMax(min!, max!, t('error_number_valid_field_decimal', {min, max})).nullable();

    const providedMetricSpecsData = useQuery<Record<string, ProvidedMetricSpec>, Error>(['providedMetricSpecs'], async ({ signal }) => {
        return providedMetricsSpecsApi.getProvidedMetricSpecs({ signal });
    });

    const metricSpecs = providedMetricSpecsData.data ?? {};
    const isLoadingSpecs = providedMetricSpecsData.isLoading;

    type Type =
        | 'INTEGER'
        | 'TEAM_MORALE'
        | 'DECIMAL'
        | 'CI_ACTIVITY'
        | 'YES_NO'
        | 'DEPLOYMENT_TARGET'
        | 'BRANCHING_STRATEGY';

    const resolverMap: Record<
        Type,
        (constraints?: ValueConstraint) => yup.AnySchema
    > = {
        INTEGER: (constraints) => IntegerOrEmptyString(constraints!),
        DECIMAL: (constraints) => PercentageOrEmptyString(constraints!),
        TEAM_MORALE: () => yup.number().min(1).max(5).nullable(),
        BRANCHING_STRATEGY: () => yup.string(),
        DEPLOYMENT_TARGET: () => yup.string(),
        CI_ACTIVITY: () => yup.string(),
        YES_NO: () => yup.string()
    };

    const sprintProvidedMetricSpecs: Record<string, MetricSpec> = {};
    const projectProvidedMetricSpecs: Record<string, MetricSpec> = {};

    if (Object.keys(metricSpecs).length > 0) {
        allowedSprintMetrics.forEach(metricKey => {
            const value = metricSpecs[metricKey];
            if (!value) {
                return;
            }
            const { type: metricType, options = [], relationship, valueConstraint } = value;
            let resolver: yup.AnySchema = yup.string();
            if (metricType && relationship) {
                resolver = resolverMap[metricType as Type](valueConstraint);

                sprintProvidedMetricSpecs[metricKey] = {
                    type: metricType,
                    resolver,
                    options,
                    category: relationship as Category,
                };
            }
        });

        const allowedProjectMetrics = ['engagementModel'];
        allowedProjectMetrics.forEach(metricKey => {
            const { type, restriction, options, relationship } = metricSpecs[metricKey];

            projectProvidedMetricSpecs[metricKey] = {
                type: type!,
                restriction,
                options: options?.length ? options : [],
                category: relationship as Category ?? 'PROJECT'
            };
        });
    }

    return {
        sprintProvidedMetricSpecs,
        projectProvidedMetricSpecs,
        isLoadingSpecs,
    };
};

export default useProvidedMetricSpecs;

import { TextField } from '@mui/material';
import { useEffect, useRef } from 'react';
import { GridRowId } from '@mui/x-data-grid';

export interface TextFieldDisplayProps {
    value?: string;
    rowsNumberWithoutScroll?: number;
    isExpanded?: boolean;
    rowId?: GridRowId | undefined;
    isRowExpandable?: boolean;
    name?: string;
    onOverflowEvaluated?: (
        rowId: GridRowId | undefined,
        fieldName: string | undefined,
        isOverflowing: boolean,
    ) => void;
}

const TextFieldDisplay = (props: TextFieldDisplayProps) => {
    const textInputRef = useRef<HTMLTextAreaElement>(null);
    const rowId = props.rowId;
    const name = props.name;
    const onOverflowEvaluated = props.onOverflowEvaluated;
    const rowsNumberWithoutScroll = props.rowsNumberWithoutScroll;
    const lineHeight = '23px';
    const maxHeight =
        rowsNumberWithoutScroll &&
        rowsNumberWithoutScroll * parseInt(lineHeight);

    const checkOverflow = () => {
        const textAreaElement = textInputRef.current;
        if (textAreaElement && onOverflowEvaluated && maxHeight) {
            if (textAreaElement.scrollHeight > maxHeight) {
                onOverflowEvaluated(rowId, name, true);
            }
        }
    };
    useEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    });

    return (
        <div>
            <TextField
                multiline
                variant="standard"
                sx={{
                    width: '100%',
                    '& .MuiInputBase-inputMultiline': {
                        overflow: 'hidden',
                        lineHeight: `${lineHeight}`,
                    },
                }}
                value={props.value ? props.value : ''}
                rows={
                    props.isRowExpandable && !props.isExpanded
                        ? props.rowsNumberWithoutScroll
                        : 0
                }
                InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                }}
                inputRef={textInputRef}
                maxRows={
                    !props.isExpanded
                        ? props.rowsNumberWithoutScroll
                        : undefined
                }
            />
        </div>
    );
};

export default TextFieldDisplay;
